import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ScrollService {

    public scrollPositionY: ReplaySubject<any> = new ReplaySubject<any>();

    public constructor() {
    }

}
